import { useState, ChangeEvent, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, Card, Form, Input, Radios } from "nhsuk-react-components";
import { GetResponse, apiGet } from '../../api/resources/device/get';
import { apiPut } from '../../api/resources/device/put';
import useAsyncEffect from 'use-async-effect';
import { ErrorResponse } from '../../lib/api/device/axioserror';
import { prepareValue } from '../../lib/api/device/prepare';

export const Edit = () => {

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [device, setDevice] = useState<GetResponse | undefined>(undefined);

  const [inputErrors, setInputErrors] = useState<ErrorResponse>({
    DeviceId: '',
    RoomName: '',
    RoomCapacity: '',
    AssetId: '',
    Frequency: '',
    Light: '',
    Debug: '',
  });

  useAsyncEffect(async () => {
    try {
      if (id !== undefined) {
        const response = await apiGet(id);
        setDevice(response);
      }
    } catch {

    }
  }, [id]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (device) {
      setDevice(prevDevice => ({
        ...prevDevice,
        [name]: prepareValue(name, value),
      }) as GetResponse);
    }
  };
  
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    try {
      const response = await apiPut(id, device);
      if (response) {
        navigate('/device');
      }
    } catch (error) {
      const axiosError = error as ErrorResponse;

      setInputErrors((prevInputErrors) => ({
        ...prevInputErrors,
        DeviceId: axiosError.DeviceId,
        RoomName: axiosError.RoomName,
        RoomCapacity: axiosError.RoomCapacity,
        AssetId: axiosError.AssetId,
        Frequency: axiosError.Frequency,
        Light: axiosError.Light,
        Debug: axiosError.Debug,
      }));
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href='/device'>Devices</Breadcrumb.Item>
        <Breadcrumb.Back href='/device'>Back to Devices</Breadcrumb.Back>
      </Breadcrumb>

    <Card feature>
      <Card.Content>
        <Card.Heading>
          Device Edit
        </Card.Heading>
        <Form onSubmit={handleSubmit}>
          {device && (
            <>
              <Input
                hint="A unique identifier sent by the device (e.g. CPU serial number)."
                id="input-DeviceId"
                label="Device ID"
                name="DeviceId"
                value={device.DeviceId}
                onChange={handleInputChange}
                error={inputErrors.DeviceId}
              />
              <Input
                hint="A human-readable name."
                id="input-RoomName"
                label="Room Name"
                name="RoomName"
                value={device.RoomName ?? ''}
                onChange={handleInputChange}
                error={inputErrors.RoomName}
              />
              <Input
                hint="The maximum number of people this room can accommodate."
                id="input-RoomCapacity"
                label="Capacity"
                name="RoomCapacity"
                value={device.RoomCapacity.toString()}
                onChange={handleInputChange}
                error={inputErrors.RoomCapacity}
              />
              <Input
                hint="A GS1 identifier for the device."
                id="input-AssetId"
                label="Asset ID"
                name="AssetId"
                value={device.AssetId ?? ''}
                onChange={handleInputChange}
                error={inputErrors.AssetId}
              />
              <Input
                hint="How frequently the device should capture images (in minutes)."
                type="number"
                id="input-Frequency"
                label="Frequency"
                name="Frequency"
                value={device.Frequency.toString()}
                onChange={handleInputChange}
                error={inputErrors.Frequency}
              />
              <Radios
                hint="Should the LED light up when the device captures an image?"
                id="input-Light"
                label="LED"
                name="Light"
                onChange={handleInputChange}
                error={inputErrors.Light}
              >
                <Radios.Radio defaultChecked={device.Light} value="true">Enable</Radios.Radio>
                <Radios.Radio defaultChecked={!device.Light} value="false">Disable</Radios.Radio>
              </Radios>
              <Radios
                hint="Should the server save the image contents for debugging purposes?"
                id="input-Debug"
                label="Debug Mode"
                name="Debug"
                onChange={handleInputChange}
                error={inputErrors.Debug}
              >
                <Radios.Radio defaultChecked={device.Debug} value="true">Enable</Radios.Radio>
                <Radios.Radio defaultChecked={!device.Debug} value="false">Disable</Radios.Radio>
              </Radios>
            </>
          )}
          <Button type="submit">Submit</Button>
        </Form>
      </Card.Content>
    </Card>
    </>
  );
}
