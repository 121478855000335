import { apiClient } from "../../apiClient"

export type GetResponse = Array<{
  Id: number;
  Device: {
    Id: number;
    DeviceId: string;
    RoomName: string;
    RoomCapacity: number;
    AssetId: string;
    LastSeen: string;
  };
  Date: string;
  RoomOccupancy: number;
}>



export const apiGet = async (since?: Date, until?: Date): Promise<GetResponse> => {
  const params = new URLSearchParams({
    ...since && { since: since.toISOString() },
    ...until && { until: until.toISOString() },
    field: 'date',
    order: 'desc',
  });
  const response = await apiClient.get(`/device/data?${params}`);
  return response.data.Data;
}
