/**
 * Returns a Date string compatible with 'datetime-local' input values.
 * 
 * @param date A Date object.
 * @returns A string comprising the 'YYYY-MM-DD hh:mm' date format.
 */
export function prepareDate(date?: Date): string {
  if (!date) {
    return '';
  }

  return getLocaleDate(date).toISOString().slice(0, -8);
}

/**
 * Returns a Date string compatible with 'time' input values.
 * 
 * @param date A Date object.
 * @returns A string comprising the 'hh:mm' date format.
 */
export function prepareTime(date?: Date): string {
  if (!date) {
    return '';
  }

  return getLocaleDate(date).toISOString().slice(11, -8);
}

/**
 * Returns a timezone-adjusted Date.
 * 
 * @param date The original date.
 * @returns A new Date.
 */
function getLocaleDate(date: Date): Date {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}
