import { MsalProvider } from '@azure/msal-react';
import { Router } from './pages/Router';
import { cachedMsalInstance } from './lib/auth/cachedMsalInstance';

const msalInstance = cachedMsalInstance();

const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <Router />
    </MsalProvider>
  )
}

export default App;
