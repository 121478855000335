import { GenericAbortSignal, isAxiosError } from "axios";
import { AxiosResponse, buildAxiosError } from "../../../lib/api/device/axioserror";
import { apiClient } from "../../apiClient";

export const apiDelete = async (id: number, signal?: GenericAbortSignal): Promise<true> => {
  try {
    await apiClient.delete(`/device/${id}`, { signal });
    return true;
  } catch (error: unknown) {
    if (isAxiosError<AxiosResponse>(error)) {
      throw await buildAxiosError(error);
    }
  }
  
  throw new Error();
}
