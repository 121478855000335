import { Configuration } from '@azure/msal-browser';
import { secrets } from '../secrets/secrets';

export const msalConfig: Configuration = {
  auth: {
    clientId: secrets.REACT_APP_AZURE_ACTIVE_DIRECTORY_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${secrets.REACT_APP_AZURE_ACTIVE_DIRECTORY_TENANT_ID}`,
    redirectUri: `${window.location.origin}/${secrets.REACT_APP_AZURE_ACTIVE_DIRECTORY_LOGIN_REDIRECT_PATH}`,
    navigateToLoginRequestUrl: false,
  }
};
