import { PropsWithChildren, useEffect, useRef } from 'react';
import { Button } from 'nhsuk-react-components';
import './Modal.scss';

type ModalProps = {
  open: boolean;
  onClose: () => void;
}

export const Modal = ({ children, open, onClose }: PropsWithChildren<ModalProps>) => {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (open) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [open]);

  return (
    <dialog className="modal" ref={ref} onCancel={onClose}>
      <div className="modal__body">
        {children}
        <Button onClick={onClose}>Close</Button>
      </div>
    </dialog>
  );
}
