import { Button, Table, Fieldset, Checkboxes, DateInput, Input, Radios, InsetText } from "nhsuk-react-components";

const clickButton = () => console.log("click");

export const Demo = () => {
  return (
    <div>
        
      <InsetText><p>This is inset text</p></InsetText>

      <Fieldset>
          <Fieldset.Legend size="m">These are buttons</Fieldset.Legend>
      </Fieldset>
      <Button onClick={clickButton}>Submit</Button>
      <Button secondary onClick={clickButton}>Submit</Button>

      <Fieldset>
          <Fieldset.Legend size="m">This is input field</Fieldset.Legend>
      </Fieldset>
      <Input label={"What is your name?"} id={"fullname"} name={"fullname"}>
      </Input>

      <Fieldset>
          <Fieldset.Legend size="m">This is input field with an error</Fieldset.Legend>
      </Fieldset>
      <Input label={"What is your name?"} id={"fullname"} name={"fullname"} error={"Please enter your full name"}>
      </Input>

      <Fieldset>
        <Fieldset>
          <Fieldset.Legend size="m">This is checkbox component</Fieldset.Legend>
        </Fieldset>
        <Checkboxes name={"contact_method"} hint={"Select all options that apply to you"}>
          <Checkboxes.Box defaultChecked={true} onChange={() => clickButton()}>
            Email
          </Checkboxes.Box>
          <Checkboxes.Box defaultChecked={false} onChange={() => clickButton()}>
            SMS
          </Checkboxes.Box>
        </Checkboxes>
      </Fieldset>
      
      <Fieldset>
        <Fieldset>
          <Fieldset.Legend size="m">This is radio component</Fieldset.Legend>
        </Fieldset>
        <Radios name={"contact_method"} hint={"Select the option that applies to you"}>
          <Radios.Radio defaultChecked={true} onChange={() => clickButton()}>
            Email
          </Radios.Radio>
          <Radios.Radio defaultChecked={false} onChange={() => clickButton()}>
            SMS
          </Radios.Radio>
        </Radios>
      </Fieldset>

      <Fieldset>
        <Fieldset>
          <Fieldset.Legend size="m">What is your date of birth?</Fieldset.Legend>
        </Fieldset>
        <DateInput hint={"For example, 31 12 1980"}>
        </DateInput>
      </Fieldset>

      <Fieldset>
          <Fieldset.Legend size="m">3 column table</Fieldset.Legend>
      </Fieldset>
      <Table responsive={true} caption={"This is a caption"}>
        <Table.Head>
          <Table.Row>
            <Table.Cell>ID</Table.Cell>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>Email</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell>1234</Table.Cell>
            <Table.Cell>John Smith</Table.Cell>
            <Table.Cell>john@domain.com</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

    </div>
  );
}
