import { Button } from "nhsuk-react-components";
import Spinner from "./icons/Spinner";
import DownArrow from "./icons/DownArrow";
import './SquareButton.scss';
import { PropsWithChildren, useState } from "react";
import { FormGroup } from "./nhs/FormGroup";

type SquareButtonProps = {
  onClick: () => Promise<void>;
  title: string;
}

export const SquareButton = ({ children, onClick, title }: PropsWithChildren<SquareButtonProps>) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  /**
   * Handle click event and disable button accordingly.
   */
  async function handleClick() {
    try {
      setDisabled(true);
      await onClick();
    }
    finally {
      setDisabled(false);
    }
  }

  return (
    <FormGroup className="icon-button">
      <Button className="icon-button-inner" disabled={disabled} onClick={handleClick} title={title}>
        { children }
      </Button>
    </FormGroup>
  );
}

export const Refresh = ({ onClick }: Pick<SquareButtonProps, 'onClick'>) => (
  <SquareButton onClick={onClick} title={"Update Date/Time"}><Spinner /></SquareButton>
);

export const Download = ({ onClick }: Pick<SquareButtonProps, 'onClick'>) => (
  <SquareButton onClick={onClick} title={"Download Logs"}><DownArrow /></SquareButton>
);
