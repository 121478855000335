import { useCallback, useEffect, useState } from 'react';
import { CartesianGrid, ResponsiveContainer, XAxis, YAxis, ScatterChart, Scatter, Tooltip, Legend, DefaultTooltipContent } from 'recharts';
import { Checkboxes, Table } from 'nhsuk-react-components';
import useAsyncEffect from 'use-async-effect';
import { GetResponse as Logs, apiGet } from '../../api/resources/log/list';
import { prepareDate } from '../../lib/date/prepare';
import { Panel, Tab, TabGroup, Tabs } from '../nhs/TabGroup';
import './Chart.scss';
import { Bins, ChartControls } from './ChartControls';

type Plot = {
  data: {
    x: number,
  }[];
};

type Maps = {
  capacity: number;
  date: number;
  name: string;
  occupancy: number;
};

type DataItem = {
  x: number,
  y: number,
  occupancy: number,
  capacity: number,
  name: string,
};

export type Data = {
  id: number,
  name: string;
  data: DataItem[];
}[];

type ScatterCell = {
  util: number,
  occupancy: number,
  capacity: number,
  name: string
};

export const Chart = () => {
  const [logs, setLogs] = useState<Logs>();
  const [plot, setPlot] = useState<Plot>();
  const [data, setData] = useState<Data>([]);
  const [bins, setBins] = useState<Bins>(Bins.m15); // eslint-disable-line

  const [sinceDate, setSinceDate] = useState<Date | undefined>(new Date(Date.now() - 6.048e+8)); // 7 days ago
  const [untilDate, setUntilDate] = useState<Date | undefined>(new Date(Date.now()));

  const [fixedTime, setFixedTime] = useState<boolean>(false);
  const [inUseOnly, setInUseOnly] = useState<boolean>(false);

  const [sinceTime, setSinceTime] = useState<Date | undefined>(new Date(1970, 0, 1, 9)); // 9am
  const [untilTime, setUntilTime] = useState<Date | undefined>(new Date(1970, 0, 1, 17)); // 5pm

  const [active, setActive] = useState<number>(0);

  const [type, setType] = useState<'number' | 'category'>('category');
  const [domain, setDomain] = useState<['auto', 'auto'] | undefined>();

  // https://service-manual.nhs.uk/design-system/styles/colour
  const colors = ['#005eb8', '#007f3b', '#ffeb3b', '#ffb81C', '#d5281b', '#7C2855', '#330072'];

  /**
   * Returns if the specified timestamp falls within fixed time.
   * @param timestamp 
   * @returns 
   */
  const withinHours = useCallback((timestamp: number): boolean => {
    if (!(fixedTime && sinceTime && untilTime && sinceTime.getTime() < untilTime.getTime())) {
      return true;
    }

    const date = new Date(timestamp);

    return (
      date.getHours() > sinceTime?.getHours() || (
        date.getHours() === sinceTime?.getHours() &&
        date.getMinutes() >= sinceTime?.getMinutes()
      )
    ) && (
        date.getHours() < untilTime?.getHours() || (
          date.getHours() === untilTime?.getHours() &&
          date.getMinutes() < untilTime?.getMinutes()
        )
      );
  }, [fixedTime, sinceTime, untilTime]);

  useAsyncEffect(fetchLogs, []);

  useEffect(() => {
    if (logs) {
      const maps: Record<number, Maps[]> = {};
      let upper: number | undefined = undefined;
      let lower: number | undefined = undefined;

      // Group logs by device identifier
      logs.forEach((log) => {
        const date = Date.parse(`${log.Date}Z`); // Interpret as UTC

        if (withinHours(date)) {
          if (!Object.hasOwn(maps, log.Device.Id)) {
            maps[log.Device.Id] = [];
          }

          maps[log.Device.Id].push({
            capacity: log.Device.RoomCapacity,
            date,
            name: log.Device.RoomName,
            occupancy: log.RoomOccupancy,
          });

          if (log.Device.Id === active) {
            if (!upper || date > upper) {
              upper = Math.floor(date / bins) * bins;
            }

            if (!lower || date < lower) {
              lower = Math.floor(date / bins) * bins;
            }
          }
        }
      });

      if (upper && lower) {
        if (Math.abs(upper - lower) <= 6.0486e+8) {
          // Generate all categories for scatter chart
          const plot = {
            data: [...Array(Math.max(1, Math.ceil(Math.abs(upper - lower) / bins)))].map((_, idx) => {
              return {
                x: lower! + bins * idx
              };
            }).filter((row) => {
              return withinHours(row.x);
            }),
          };

          setPlot(plot);

          setDomain(undefined);
          setType('category');
        }
        else {
          setPlot(undefined);

          setFixedTime(false);
          setDomain(['auto', 'auto']);
          setType('number');
        }
      }
      else {
        setPlot(undefined);
        setDomain(undefined);
        setType('category');
      }

      // Calculate scatter plot
      const data = Object.entries(maps).map(([key, val]) => {
        const map = val.reduce((acc, row) => {
          const date = Math.floor(row.date / bins) * bins;
          const util = row.occupancy;
          if (!acc.has(date) || acc.get(date)?.util! < util) {
            acc.set(date, { util, occupancy: row.occupancy, capacity: row.capacity, name: val[0].name }); // Saves the highest value
          }
          return acc;
        }, new Map<number, ScatterCell>());

        return {
          id: parseInt(key),
          name: val[0].name,
          data: Array.from(map).map(([x, d]): DataItem => {
            return {
              x,
              y: d.util,
              occupancy: d.occupancy,
              capacity: d.capacity,
              name: d.name
            };
          }).reverse().filter((row) => {
            return withinHours(row.x);
          }),
        };
      });

      setData(data);

      if (!active) {
        setActive(data[0].id);
      }
    }
  }, [active, bins, logs, fixedTime, sinceDate, sinceTime, untilDate, untilTime, withinHours]);

  async function fetchLogs() {
    try {
      const response = await apiGet(sinceDate, untilDate);
      setLogs(response);
    } catch {
      // TODO: how to handle failure?
    }
    finally {
      if (document.activeElement) {
        (document.activeElement as HTMLInputElement).blur(); // Clear select state
      }
    }
  }

  /**
   * Generate and download CSV from current logs.
   */
  async function fetchCSVs() {
    // Convert logs into CSV format
    const csv = [
      [
        'Log ID',
        'Device ID',
        'Room Name',
        'Date',
        'Room Capacity',
        'Room Occupancy',
      ].join(','),
    ].concat(logs?.map((log) => (
      [
        log.Id,
        log.Device.Id,
        log.Device.RoomName,
        log.Date,
        log.Device.RoomCapacity,
        log.RoomOccupancy,
      ].join(',')
    )) ?? []).join('\r\n');    

    // Create and click on a download link
    const a = document.createElement('a');
    const blob = new Blob([csv], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    a.setAttribute('href', url);
    a.setAttribute('download', `logs-${new Date().valueOf()}.csv`);
    a.click();
  }

  /**
   * Returns formatted values for the chart tooltips.
   *
   * @param value The chart tooltip value.
   * @param name The chart tooltop name.
   * @returns A formatted chart tooltip value.
   */
  function prepareHint<TValue, TName>(value: TValue, name: TName): TValue {
    if (typeof value === 'number') {
      switch (name) {
        case 'Date/Time':
          return prepareTick(value) as TValue;
        case 'Utilisation':
          return value.toFixed(1) as TValue;
      }
    }
    return value;
  }
  /**
   * Returns formatted values for the chart axis ticks.
   *
   * @param timestamp 
   * @returns 
   */
  function prepareTick(timestamp: number): string {
    return prepareDate(new Date(timestamp)).replace('T', ' ');
  }

  function calculateUsage(row: { data: { x: number, y: number; }[]; }): string {
    const data = row.data.filter((row) => inUseOnly ? row.y > 0 : true);
    return (data.reduce((acc, row) => row.y > 0 ? acc + 1 : acc, 0) / data.length * 100).toFixed(1);
  }

  function calculateUtilisation(row: { data: { x: number, y: number; }[]; }): string {
    const data = row.data.filter((row) => inUseOnly ? row.y > 0 : true);
    return (data.reduce((acc, row) => acc + row.y, 0) / data.length).toFixed(1);
  }

  return (
    <div>
      {data && active && (
        <ChartControls
          active={active}
          setActive={setActive}
          data={data}
          bins={bins}
          setBins={setBins}
          fixedTime={fixedTime}
          setFixedTime={setFixedTime}
          sinceDate={sinceDate}
          setSinceDate={setSinceDate}
          sinceTime={sinceTime}
          setSinceTime={setSinceTime}
          untilDate={untilDate}
          setUntilDate={setUntilDate}
          untilTime={untilTime}
          setUntilTime={setUntilTime}
          onClickRefresh={fetchLogs}
          onClickDownload={fetchCSVs}
          type={type}
        />
      )}
      <TabGroup>
        <Tabs>
          <Tab id='graph' selected>Graph</Tab>
          <Tab id='table'>Table</Tab>
        </Tabs>
        <Panel id='graph' selected>
          <div className='contents'>
            <ResponsiveContainer width={'99%'} height={400}>
              {data.length > 0 ? (
                <ScatterChart margin={{ top: 10, right: 110, bottom: 60, left: 60 }}>
                <CartesianGrid />
                <Tooltip formatter={prepareHint} content={<CustomTooltip />} />
                <Legend name='name' verticalAlign='top' />
                <XAxis type={type} domain={domain} dataKey='x' name='Date/Time' tickFormatter={prepareTick} allowDuplicatedCategory={false} angle={30} textAnchor='start' />
                <YAxis type='number' dataKey='y' name='Utilisation' />
                {plot && (
                  <Scatter isAnimationActive={false} data={plot.data} legendType='none' />
                )}
                {data.filter((row) => row.id === active && row.data.length > 0).map((scatter, idx) => (
                  <Scatter key={`scatter-${idx}`} name={scatter.name} data={scatter.data} fill={colors[idx % colors.length]} />
                ))}
              </ScatterChart>
              ) : (
                <p className='text-align-center vertical-align-middle'><em>-- No data for selected date/time range --</em></p>
              )}

            </ResponsiveContainer>
          </div>
        </Panel>
        <Panel id='table'>
          <Checkboxes>
            <Checkboxes.Box checked={inUseOnly} onChange={() => setInUseOnly(!inUseOnly)}>In-use Only</Checkboxes.Box>
          </Checkboxes>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Cell width='10%'>#</Table.Cell>
                <Table.Cell width='50%'>Room</Table.Cell>
                <Table.Cell width='20%'>Usage</Table.Cell>
                <Table.Cell width='20%'>Utilisation</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {data.length > 0 ? data.map((row, idx) => (
                <Table.Row key={`row-${idx}`}>
                  <Table.Cell>{row.id}</Table.Cell>
                  <Table.Cell>{row.name}</Table.Cell>
                  <Table.Cell>{calculateUsage(row)}%</Table.Cell>
                  <Table.Cell>{calculateUtilisation(row)}%</Table.Cell>
                </Table.Row>
              )) : (
                <Table.Row key={'row-none'}>
                  <Table.Cell colSpan={4} className='text-align-center'><em>-- No data for selected date/time range --</em></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Panel>
      </TabGroup>
    </div>
  );
};

// Based on: https://github.com/recharts/recharts/issues/275#issuecomment-386696660

const CustomTooltip = (props: any) => {
  // payload[0] doesn't exist when tooltip isn't visible
  if (props.payload[0] != null) {
    // mutating props directly is against react's conventions
    // so we create a new payload with the name and value fields set to what we want
    const newPayload = [
      {
        name: 'Room Name',
        value: props.payload[1].payload.name,
      },
      ...props.payload,
      {
        name: 'Occupancy',
        value: `${props.payload[1].payload.occupancy} / ${props.payload[1].payload.capacity}`,
      },

    ];

    // we render the default, but with our overridden payload
    return <DefaultTooltipContent {...props} payload={newPayload} label={null} />;
  }

  // we just render the default
  return <DefaultTooltipContent {...props} />;
};
