import { apiClient } from "../../apiClient"

export const apiGetFile = async (id: number): Promise<string> => {
  try {
    const response = await apiClient.get(`/device/${id}/data`, { responseType: 'blob' });
    return window.URL.createObjectURL(response.data);
  } catch (error: unknown) {
    console.error(error);
    return '';
  }
}
