import { useIsAuthenticated } from '@azure/msal-react';
import { Outlet } from 'react-router-dom';

export const ProtectedRoute = () => {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return null;
  }

  return <Outlet />
}