import { isAxiosError } from "axios";
import { apiClient } from "../../apiClient"
import { AxiosResponse, buildAxiosError} from "../../../lib/api/device/axioserror"

export type PutRequest = {
  DeviceId: string;
  RoomName: string;
  RoomCapacity: number;
  AssetId?: string;
  Frequency: number;
  Light: boolean;
  Debug: boolean;
};

export type PutResponse = {
  Id: number;
  DeviceId: string;
  RoomName: string;
  RoomCapacity: number;
  AssetId?: string;
  LastSeen?: string;
  Frequency: number;
  Light: boolean;
  Debug: boolean;
};

export const apiPut = async (id: string | undefined, deviceData: PutRequest | undefined): Promise<PutResponse> => {
  try {
    const response = await apiClient.put(`/device/${id}`, deviceData);
    return response.data.Data;
  } catch (error: unknown) {
    if (isAxiosError<AxiosResponse>(error)) {
      throw await buildAxiosError(error);
    }
  }
  
  throw new Error();
};

