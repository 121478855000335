import { apiClient } from "../../apiClient"

export type GetResponse = {
  Id: number;
  DeviceId: string;
  RoomName: string;
  RoomCapacity: number;
  AssetId?: string;
  LastSeen?: string;
  Frequency: number;
  Light: boolean;
  Debug: boolean;
};

export const apiGet = async (id: string): Promise<GetResponse> => {
  const response = await apiClient.get(`/device/${id}`);
  return response.data.Data;
}
