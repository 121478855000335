import { AxiosError } from "axios";

export type AxiosResponse = {
    errors: {
        DeviceId: [string];
        RoomName: [string];
        RoomCapacity: [string];
        AssetId: [string];
        Frequency: [string];
        Light: [string];
        Debug: [string];
    }
}

export type ErrorResponse = {
    DeviceId: string;
    RoomName: string;
    RoomCapacity: string;
    AssetId: string;
    Frequency: string;
    Light: string;
    Debug: string;
}

export const buildAxiosError = async (axiosError: AxiosError<AxiosResponse>): Promise<ErrorResponse> => {
    const errorResponse: ErrorResponse = {
        DeviceId: '',
        RoomName: '',
        RoomCapacity: '',
        AssetId: '',
        Frequency: '',
        Light: '',
        Debug: '',
    };

    if (axiosError.response?.data) {
        const responseData = axiosError.response.data;

        if (responseData.errors) {
        for (const key in errorResponse) {
            if (responseData.errors[key as keyof ErrorResponse]) {
            errorResponse[key as keyof ErrorResponse] = responseData.errors[key as keyof ErrorResponse][0];
            }
        }
        }
    }

    return errorResponse;
};
