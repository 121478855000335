import { useState, ChangeEvent, FormEvent } from 'react';
import { Breadcrumb, Button, Card, Form, Input, Radios } from "nhsuk-react-components";
import { useNavigate } from 'react-router-dom';
import { apiPost } from '../../api/resources/device/post';
import { ErrorResponse } from '../../lib/api/device/axioserror';
import { prepareValue } from '../../lib/api/device/prepare';

export const New = () => {
  const navigate = useNavigate();

  const [deviceData, setDeviceData] = useState({
    DeviceId: "",
    RoomName: "",
    RoomCapacity: 0,
    AssetId: "",
    Frequency: 15,
    Light: true,
    Debug: true,
  });

  const [inputErrors, setInputErrors] = useState<ErrorResponse>({
    DeviceId: "",
    RoomName: "",
    RoomCapacity: "",
    AssetId: "",
    Frequency: "",
    Light: "",
    Debug: "",
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setDeviceData((prevData) => ({
      ...prevData,
      [name]: prepareValue(name, value),
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    try {
      const response = await apiPost(deviceData);
      if (response) {
        navigate('/device');
      }
    } catch (error) {
      const axiosError = error as ErrorResponse;
      
      setInputErrors((prevInputErrors) => ({
        ...prevInputErrors,
        DeviceId: axiosError.DeviceId,
        RoomName: axiosError.RoomName,
        RoomCapacity: axiosError.RoomCapacity,
        AssetId: axiosError.AssetId,
        Frequency: axiosError.Frequency,
        Light: axiosError.Light,
        Debug: axiosError.Debug,
      }));
    }
  };
  
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item href='/device'>Devices</Breadcrumb.Item>
        <Breadcrumb.Back href='/device'>Back to Devices</Breadcrumb.Back>
      </Breadcrumb>

      <Card feature>
        <Card.Content>
          <Card.Heading>
            Add Device
          </Card.Heading>
          <Form onSubmit={handleSubmit}>
            <Input
              hint="A unique identifier sent by the device (e.g. CPU serial number)."
              id="input-DeviceId"
              label="Device ID"
              name="DeviceId"
              value={deviceData.DeviceId}
              onChange={handleInputChange}
              error={inputErrors.DeviceId}
            />
            <Input
              hint="A human-readable name."
              id="input-RoomName"
              label="Room"
              name="RoomName"
              value={deviceData.RoomName}
              onChange={handleInputChange}
              error={inputErrors.RoomName}
            />
            <Input
              hint="The maximum number of people this room can accommodate."
              type="number"
              id="input-RoomCapacity"
              label="Capacity"
              name="RoomCapacity"
              value={deviceData.RoomCapacity}
              onChange={handleInputChange}
              error={inputErrors.RoomCapacity}
            />
            <Input
              hint="A GS1 identifier for the device."
              id="input-AssetId"
              label="Asset ID"
              name="AssetId"
              value={deviceData.AssetId}
              onChange={handleInputChange}
              error={inputErrors.AssetId}
            />
            <Input
              hint="How frequently the device should capture images (in minutes)."
              type="number"
              id="input-Frequency"
              label="Image Frequency"
              name="Frequency"
              value={deviceData.Frequency.toString()}
              onChange={handleInputChange}
              error={inputErrors.Frequency}
            />
            <Radios
              hint="Should the LED light up when the device captures an image?"
              id="input-Light"
              label="LED"
              name="Light"
              onChange={handleInputChange}
              error={inputErrors.Light}
            >
              <Radios.Radio defaultChecked={true} value="true">Enable</Radios.Radio>
              <Radios.Radio value="false">Disable</Radios.Radio>
            </Radios>
            <Radios
              hint="Should the server save the image contents for debugging purposes?"
              id="input-Debug"
              label="Debug Mode"
              name="Debug"
              onChange={handleInputChange}
              error={inputErrors.Debug}
            >
              <Radios.Radio defaultChecked={true} value="true">Enable</Radios.Radio>
              <Radios.Radio value="false">Disable</Radios.Radio>
            </Radios>
            <Button type="submit">Submit</Button>
          </Form>
        </Card.Content>
      </Card>
    </>
  );
}
