import { apiClient } from "../../apiClient"

export type GetResponse = Array<{
  Id: number;
  DeviceId: string;
  RoomName: string;
  RoomCapacity: number;
  AssetId: string;
  LastSeen: string;
  Frequency: number;
  Light: boolean;
  Version: string;
  IPv4: string;
  Log: {
    Id: number;
    Date: string;
    RoomOccupancy: number;
    Data: string | null;
    DataSize: number;
  };
}>

export const apiGet = async (): Promise<GetResponse> => {
  const response = await apiClient.get(`/device`);
  return response.data.Data.map((({ Logs, ...Data }: any) => ({ ...Data, Log: Logs[0] ?? null })));
}
