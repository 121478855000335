import { Outlet } from "react-router-dom"
import { NHSHeader } from "./partials/NHSHeader"
import { NHSFooter } from "./partials/NHSFooter"

type LayoutProps = {
  className?: string;
}

const Layout = ({ className }: LayoutProps) => {
  const classNameArray = ['body-container'];

  if (className) {
    classNameArray.push(className);
  }

  return (
    <>
      <NHSHeader></NHSHeader>
      <div className={classNameArray.join(' ')}>
        <Outlet />
      </div>
      <NHSFooter></NHSFooter>
    </>
  )
}

export const BasicLayout = () => Layout({ className: 'nhsuk-width-container' });
export const FluidLayout = () => Layout({ className: 'nhsuk-width-container-fluid' });
