/**
 * Returns a value in the correct format.
 * 
 * @param name The property name.
 * @param value The property value.
 * @returns An appropriately-typed value.
 */
export function prepareValue(name: string, value: string): boolean | number | string {
  switch (name) {
    case 'Debug':
    case 'Light':
      return value === 'true';
    case 'RoomCapacity':
    case 'Frequency':
      return Number(value ?? 0);
  }
  
  return value;
}
