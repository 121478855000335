import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from "nhsuk-react-components";
import moment from 'moment';
import useAsyncEffect from 'use-async-effect';
import { apiDelete } from '../../api/resources/device/delete';
import { apiGetFile } from '../../api/resources/device/getFile';
import { GetResponse, apiGet } from '../../api/resources/device/list';
import { Modal } from '../Modal';
import './Index.scss';

export const Index = () => {
  const [devices, setDevices] = useState<GetResponse | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<string>();
  
  useAsyncEffect(async () => {
    if (!devices) {
      try {
        const response = await apiGet();
        setDevices(response); 
      } catch {

      }
    }
    
    const timer = setInterval(async () => {
      const response = await apiGet();
      setDevices(response);
    }, 60000); // updates once per minute

    return () => {
      clearInterval(timer); // clears on unmount
    }
  }, []);

  /**
   * Returns the appropriate CSS class name to represent the status of a device. If the device was
   * last seen within one capture interval, it shows green. If the device was seen within one to
   * four capture intervals, it shows amber. If the device was not seen within four capture
   * intervals (or never seen), it shows red.
   * @param date The last-seen date, corrected to UTC.
   * @param frequency The capture interval, in minutes.
   * @returns 
   */
  function getStatus(date: Date | null, frequency: number): string {
    if (!date) {
      return 'status__red';
    }

    const diff = new Date().getTime() - date.getTime();

    if (diff < frequency * 60000) {
      return 'status__green';
    }
    else
    if (diff < frequency * 240000) {
      return 'status__amber';
    }
    return 'status__red';
  }

  async function removeDevice(id: number) {
    try {
      if (window.confirm("Are you sure you want to delete this device?") === true) {
        await apiDelete(id);
        const response = await apiGet();
        setDevices(response); 
      }
    } catch (error) {
      // TODO: Decide if we need to perform any remedial actions
    }
  }

  async function getData(id: number) {
    const blob = await apiGetFile(id);
    setData(blob);
    setOpen(true);
  }

  return (
    <>
      <Table.Panel heading="Devices">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell width='33%'>Room</Table.Cell>
              <Table.Cell width='33%'>Device ID</Table.Cell>
              <Table.Cell width='33%'>Asset ID</Table.Cell>
              <Table.Cell>Capacity</Table.Cell>
              <Table.Cell>Frequency</Table.Cell>
              <Table.Cell>LED</Table.Cell>
              <Table.Cell title='Software version from the most-recent heartbeat event'>Version</Table.Cell>
              <Table.Cell title='IP address from the most-recent heartbeat event'>IPv4</Table.Cell>
              <Table.Cell>Last Seen</Table.Cell>
              <Table.Cell>Last Occupancy</Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>

            {devices?.map((device) => {
              const date = device.Log?.Date ? new Date(`${device.Log.Date}Z`) : null;
              const className = getStatus(date, device.Frequency);
              const title = device.Log?.Date ? `Last seen on ${date}` : 'Never seen';
              const lastDate = device.Log?.Date ? moment(date).fromNow() : 'Never';

              return (
                <Table.Row key={device.Id}>
                  <Table.Cell className={className} title={title} onDoubleClick={() => removeDevice(device.Id)}>
                    ⬤
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/device/${device.Id}`}>
                      {device.RoomName || '(Empty)'}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{device.DeviceId}</Table.Cell>
                  <Table.Cell>{device.AssetId}</Table.Cell>
                  <Table.Cell>{device.RoomCapacity}</Table.Cell>
                  <Table.Cell>{device.Frequency}</Table.Cell>
                  <Table.Cell title={device.Light ? 'Enabled' : 'Disabled'}>
                    {device.Light ? '✓' : '✗'}
                  </Table.Cell>
                  <Table.Cell>{device.Version ?? '✗'}</Table.Cell>
                  <Table.Cell>{device.IPv4 ?? '✗'}</Table.Cell>
                  <Table.Cell>{lastDate}</Table.Cell>
                  <Table.Cell>{device.Log ? device.Log.RoomOccupancy : '✗'}</Table.Cell>
                  <Table.Cell>
                    <Link to='#' onClick={() => getData(device.Id)}>
                      Show
                    </Link>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Table.Panel>
      <Button href='/device/new'>Add Device</Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <img src={data} alt='' />
      </Modal>
    </>
  );
}
