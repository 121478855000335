import axios from "axios";
import {applyAccessTokenToRequest} from "./interceptors/request/applyAccessTokenToRequest";

const apiClient = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Request interceptors
apiClient.interceptors.request.use(
  applyAccessTokenToRequest,
  Promise.reject
);

export { apiClient };
