// Types
type DownArrowProps = {
  className?: string;
};

const DownArrow = ({ className }: DownArrowProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentcolor"
    className={className}
    viewBox="0 0 24 24"
  >
    <path d="m20.561 14.561-7.5 7.5a1.5 1.5 0 0 1-2.121 0l-7.5-7.5a1.5 1.5 0 1 1 2.121-2.121l4.939 4.939V3a1.5 1.5 0 0 1 3 0v14.379l4.939-4.939c.293-.293.677-.439 1.061-.439s.768.146 1.061.439a1.5 1.5 0 0 1 0 2.121z" />
  </svg>
)
export default DownArrow
