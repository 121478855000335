import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "../../config/msal/msalConfig";

let msalInstance: PublicClientApplication | undefined;

/**
 * Create and/or return existing MSAL (Microsoft Authentication Library) instance for use
 * throughout the app.
 */
export const cachedMsalInstance = (): PublicClientApplication => {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig);
  }

  return msalInstance;
}
