import { Footer } from "nhsuk-react-components";

export const NHSFooter = () => {
  return (
    <div>      
      <Footer>
        <Footer.List>
          <Footer.ListItem href="/">Home</Footer.ListItem>
          <Footer.ListItem href="/device">Devices & Rooms</Footer.ListItem>
          <Footer.ListItem href="https://thecurve.io/" target="_blank">Powered by The Curve</Footer.ListItem>
        </Footer.List>
        <Footer.Copyright>&copy; Crown copyright</Footer.Copyright>
      </Footer>
    </div>
  )
}
