import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const Graph = () => {
  const data = [
    {
      name: 'Room A',
      capacity: 40,
      usage: 20,
    },
    {
      name: 'Room B',
      capacity: 30,
      usage: 13,
    },
    {
      name: 'Room C',
      capacity: 20,
      usage: 98,
    },
    {
      name: 'Room D',
      capacity: 27,
      usage: 39,
    },
    {
      name: 'Room E',
      capacity: 18,
      usage: 48,
    },
    {
      name: 'Room F',
      capacity: 23,
      usage: 38,
    },
    {
      name: 'Room G',
      capacity: 34,
      usage: 43,
    },
  ];

  return (
    <>
      <h1>Rechart Demo</h1>
      <ResponsiveContainer  width={'99%'} height={400}>
        <BarChart
          height={400}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="capacity" fill="#8884d8" />
          <Bar dataKey="usage" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>

    </>
  )
}
