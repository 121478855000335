import { PropsWithChildren, useEffect } from "react";

// @ts-ignore
import ReactTabs from 'nhsuk-frontend/packages/components/tabs/tabs';

type TabGroupType = {
  namespace?: string;
}

/**
 * A container for tabbed elements.
 *
 * @param {object} props a list of component properties
 * @param {string} props.namespace the namespace string to bind JS behaviour
 * @param {object} props.children the JSX child elements
 * @returns A JSX element
 */
export const TabGroup = ({ namespace = "nhsuk-tabs", children }: PropsWithChildren<TabGroupType>) => {
  useEffect(() => {
    ReactTabs({ namespace }); // Initialise JS from NHSUK module manually
  }, [namespace]);

  return <div className="nhsuk-tabs" data-module={namespace}>
    {children}
  </div>
}

/**
 * A container for tab headings.
 *
 * @param {object} props a list of component properties
 * @param {object} props.children the JSX child elements
 * @returns A JSX element
 */
export const Tabs = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ul className="nhsuk-tabs__list" role="tablist">
      {children}
    </ul>
  );
};

type TabProps = {
  id: string;
  selected?: true;
}

/**
 * A tab header element.
 *
 * @param {object} props a list of component properties
 * @param {string} props.id a unique string identifier
 * @param {boolean} props.selected the selected state
 * @param {object} props.children the JSX child elements
 * @returns A JSX element
 */
export const Tab = ({ id, selected, children }: PropsWithChildren<TabProps>) => {
  const className = ["nhsuk-tabs__list-item"];
  if (selected) {
    className.push("nhsuk-tabs__list-item--selected");
  }
  return <li className={className.join(' ')} role="presentation">
    <a className="nhsuk-tabs__tab" href={`#${id}`} role="tab">
      {children}
    </a>
</li>
};

type PanelProps = {
  id: string;
  selected?: true;
}

/**
 * A tab panel element.
 *
 * @param {object} props a list of component properties
 * @param {string} props.id a unique string identifier
 * @param {boolean} props.selected the selected state
 * @param {object} props.children the JSX child elements
 * @returns A JSX element
 */
export const Panel = ({ id, selected, children }: PropsWithChildren<PanelProps>) => {
  const className = ["nhsuk-tabs__panel"];
  if (!selected) {
    className.push("nhsuk-tabs__panel--hidden");
  }
  return (
    <div className={className.join(' ')} id={id} role="tabpanel">
      {children}
    </div>
  );
};
