import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

/**
 * Microsoft Authentication Library will redirect the user to a route that
 * will render this page.
 *
 * This page will then mark the logged in account as active and then redirect back to the
 * authenticated user flow (in our case, the home page).
 *
 * @see useMsalAuthentication() at {@link src/pages/Router.tsx}
 * @see {@link src/config/msal/msalConfig.ts}
 */
export const LoginCompletePage = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    if (isAuthenticated) {
      const mainAccount = instance.getAllAccounts()[0];
      instance.setActiveAccount(mainAccount);

      navigate('/', { replace: true });
    }
  }, [navigate, isAuthenticated, instance]);

  return null;
}
