import { PropsWithChildren } from "react";

type FormGroupProps = {
  className?: string;
}

/**
 * A container for input elements.
 *
 * @param {object} props a list of component properties
 * @param {object} props.children the JSX child elements
 * @returns A JSX element
 */
export const FormGroup = ({ className, children }: PropsWithChildren<FormGroupProps>) => {

  const classNameArray = ["nhsuk-form-group"];
  if (className) {
    classNameArray.push(className);
  }

  return <div className={classNameArray.join(' ')}>
    {children}
  </div>
}
