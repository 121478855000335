import { Checkboxes, Input, Radios, Select } from "nhsuk-react-components";
import { FormGroup } from "../nhs/FormGroup";
import { prepareDate, prepareTime } from "../../lib/date/prepare";
import { ChangeEvent, SetStateAction } from "react";
import './ChartControls.scss';
import { Download, Refresh } from "../SquareButton";
import { Data } from "./Chart";

type DateType = Date | undefined;
type SetState<T> = (value: SetStateAction<T>) => void;

export enum Bins {
  m15 =  900000, // 15 minutes
  m30 = 1800000, // 30 minutes
  m60 = 3600000, // 60 minutes
};

type ChartControlsProps = {
  data: Data,
  active: number,
  setActive: SetState<number>,
  bins: Bins,
  setBins: SetState<Bins>,
  fixedTime: boolean,
  setFixedTime: SetState<boolean>,
  sinceDate: DateType,
  setSinceDate: SetState<DateType>,
  sinceTime: DateType,
  setSinceTime: SetState<DateType>,
  untilDate: DateType,
  setUntilDate: SetState<DateType>,
  untilTime: DateType,
  setUntilTime: SetState<DateType>,
  onClickDownload: () => Promise<void>,
  onClickRefresh: () => Promise<void>,
  type: 'number' | 'category',
}

export const ChartControls = ({
  data,
  active,
  setActive,
  setBins,
  fixedTime,
  setFixedTime,
  sinceDate,
  setSinceDate,
  sinceTime,
  setSinceTime,
  untilDate,
  setUntilDate,
  untilTime,
  setUntilTime,
  onClickDownload,
  onClickRefresh,
  type
}: ChartControlsProps) => {
/**
   * Sets a given React state to a given date, or undefined if not possible.
   *
   * @param event An HTML input change event.
   * @param fn A React state action callback.
   */
  function handleDate(event: ChangeEvent<HTMLInputElement>, fn: (value: SetStateAction<Date | undefined>) => void) {
    const date = new Date(event.target.value);
    fn(!isNaN(date.getTime()) ? date : undefined);
  }

  /**
   * Sets a given React state to a given date, or undefined if not possible.
   *
   * @param event An HTML input change event.
   * @param fn A React state action callback.
   */
  function handleTime(event: ChangeEvent<HTMLInputElement>, fn: (value: SetStateAction<Date | undefined>) => void) {
    const [hour, minute] = event.target.value.split(':').map((x) => parseInt(x));
    const date = new Date(1970, 0, 1, hour, minute);
    fn(!isNaN(date.getTime()) ? date : undefined);
  }

  function handleSinceDate(event: ChangeEvent<HTMLInputElement>) {
    handleDate(event, setSinceDate);
  }

  function handleUntilDate(event: ChangeEvent<HTMLInputElement>) {
    handleDate(event, setUntilDate);
  }

  function handleSinceTime(event: ChangeEvent<HTMLInputElement>) {
    handleTime(event, setSinceTime);
  }

  function handleUntilTime(event: ChangeEvent<HTMLInputElement>) {
    handleTime(event, setUntilTime);
  }

  return (
    <div className='graph-controls'>

      <div className='graph-controls__group'>
        <Select disabled={data.length === 0} value={active} onChange={(e) => setActive(Number(e.currentTarget.value))}>
          {data.length > 0 ? data.map((row) => (
            <Select.Option value={row.id} key={row.id}>{row.name}</Select.Option>
          )) : (
            <Select.Option value={active}>None</Select.Option>
          )}
        </Select>
      </div>
      <div className='graph-controls__group'>
        <Input
          id='date-since' label='Start date' step='300' type='datetime-local' width='10'
          onChange={handleSinceDate} value={prepareDate(sinceDate)}
        />
        <FormGroup className='graph-controls__separator'>/</FormGroup>
        <Input
          id='date-until' label='End date' step='300' type='datetime-local' width='10'
          onChange={handleUntilDate} value={prepareDate(untilDate)}
        />
        <Refresh onClick={onClickRefresh} />
        <Download onClick={onClickDownload} />
      </div>
      <div className='graph-controls__group'>
      <Checkboxes label='Filter by specific time range'>
        <Checkboxes.Box checked={fixedTime} disabled={type !== 'category'} onChange={() => setFixedTime(!fixedTime)}>Fixed Time</Checkboxes.Box>
      </Checkboxes>
      <Input
        id='time-since' disabled={!fixedTime} label='Start time' step='300' type='time' width='5'
        onChange={handleSinceTime} value={prepareTime(sinceTime)}
      />
      <FormGroup className='graph-controls__separator'>/</FormGroup>
      <Input
        id='time-until' disabled={!fixedTime} label='End time' step='300' type='time' width='5'
        onChange={handleUntilTime} value={prepareTime(untilTime)}
      />
      </div>
      <div className='graph-controls__group'>
        <Radios label='Select an aggregate scale' onChange={(e: ChangeEvent<HTMLInputElement>) => setBins(parseInt(e.target.value))}>
          <Radios.Radio value={Bins.m15} defaultChecked={true}>15m</Radios.Radio>
          <Radios.Radio value={Bins.m30}>30m</Radios.Radio>
          <Radios.Radio value={Bins.m60}>60m</Radios.Radio>
        </Radios>
      </div>
    </div>
  );
}
