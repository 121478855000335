import { isAxiosError } from "axios";
import { apiClient } from "../../apiClient"
import { AxiosResponse, buildAxiosError} from "../../../lib/api/device/axioserror"

export type PostRequest = {
  DeviceId: string;
  RoomName: string;
  RoomCapacity: number;
  AssetId?: string;
  Frequency: number;
  Light: boolean;
  Debug: boolean;
};

export type PostResponse = {
  Id: number;
  DeviceId: string;
  RoomName: string;
  RoomCapacity: number;
  AssetId?: string;
  LastSeen?: string;
  Frequency: number;
  Light: boolean;
  Debug: boolean;
};

export const apiPost = async (deviceData: PostRequest): Promise<PostResponse> => {
  try {
    const response = await apiClient.post(`/device`, deviceData);
    return response.data.Data;
  } catch (error: unknown) {
    if (isAxiosError<AxiosResponse>(error)) {
      throw await buildAxiosError(error);
    }
  }
  
  throw new Error();
};

