// Types
type SpinnerProps = {
  className?: string;
};

const Spinner = ({ className }: SpinnerProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentcolor"
    className={className}
    viewBox="0 0 24 24"
  >
    <path d="M24 9h-9l3.364-3.364C16.664 3.936 14.404 3 12 3s-4.664.936-6.364 2.636C3.936 7.336 3 9.596 3 12s.936 4.664 2.636 6.364C7.336 20.064 9.596 21 12 21a8.94 8.94 0 0 0 6.773-3.074l2.258 1.975A11.971 11.971 0 0 1 12 23.999c-6.627 0-12-5.373-12-12s5.373-12 12-12c3.314 0 6.313 1.344 8.485 3.515L24-.001v9z" />
  </svg>
)
export default Spinner
