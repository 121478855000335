import { Header } from "nhsuk-react-components";

export const NHSHeader = () => {
  return (
    <div>      
      <Header orgName="Room Occupancy Monitoring" orgDescriptor="By The Curve">
        <Header.Container>
            <Header.Logo href="/" />
        </Header.Container>
      </Header>
    </div>
  )
}
