import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { checkSecrets } from './config/secrets/checkSecrets';
import App from './App';
import './index.scss';

/**
 * Check whether the secrets.ts file at ./config/secrets/secrets.ts is configured.
 */
checkSecrets();

// https://github.com/nhsuk/nhsuk-frontend/blob/HEAD/docs/installation/installing-with-npm.md#importing-javascript
document.body.className = ((document.body.className) ? `${document.body.className} js-enabled` : 'js-enabled');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
