import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { msalLoginRequest } from "../config/msal/msalLoginRequest";
import { Demo } from "../components/Demo";
import { Graph } from "../components/Graph";
import { BasicLayout, FluidLayout } from "../components/Layout";
import { LoginCompletePage } from "./auth/LoginCompletePage";
import { ProtectedRoute } from "../components/auth/ProtectedRoute";
import { Index } from "../components/device/Index";
import { New } from "../components/device/New";
import { Edit } from "../components/device/Edit";
import { Chart } from "../components/log/Chart";
  
  export const baseUrl: string | undefined = document.querySelector('base')?.getAttribute('href') ?? undefined;
  
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<BasicLayout />}>
          <Route element={<ProtectedRoute />}>
            <Route index path="/demo" element={<Demo />} />
            <Route index path="/graph" element={<Graph />} />
            <Route index path="/device/new" element={<New />} />
            <Route index path="/device/:id" element={<Edit />} />
            <Route path="/auth/login/complete" element={<LoginCompletePage />} />
          </Route>
        </Route>

        <Route element={<FluidLayout />}>
          <Route element={<ProtectedRoute />}>
            <Route index path="/device" element={<Index />} />
            <Route index path="/" element={<Chart />} />
          </Route>
        </Route>
      </>
    ),
    { basename: baseUrl }
  );
  
  export const Router = () => {
    /**
     * Initiates a login if a user is not already signed in,
     * otherwise attempt to acquire a token.
     */
    useMsalAuthentication(InteractionType.Redirect, msalLoginRequest);
  
    return (
      <RouterProvider router={router} />
    )
  }
