import { AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios";
import { getAccessTokenSilently } from "../../../lib/auth/getAccessToken";

export const applyAccessTokenToRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  if (!config.headers) {
    config.headers = {} as AxiosRequestHeaders;
  }

  const accessToken = await getAccessTokenSilently();
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};